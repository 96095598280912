// src/components/header/Header.jsx
import React from 'react';
import './header.css';
import CTA from './CTA';
import jose from '../../assets/jose.png';
import HeaderSocial from './HeaderSocial';
import Pitch from './pitch';

function Header() {
  return (
    <header>
      <div className="container header__container">
        <h3 className="introduction">Hello I'm</h3>
        <h1 className="names">Mugo Joseph Wamiti</h1>
        <h1 className="text-light">
          Software Engineer <br />  Full-Stack Software Developer <br /> Creative Enthusiast
          </h1>
        <CTA />
        <HeaderSocial />

        {/* Add my pitch lasts 25 secs */}
        <Pitch />
      </div>

      <a href="#contact" className="scroll__down">Scroll Down</a>
    </header>
  );
}

export default Header;
