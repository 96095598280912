import React from 'react';
import './services.css';
import { BiCheck } from 'react-icons/bi';

function Services() {
  return (
    <section id='services'>
      <h5>What I Offer</h5>
      <h2>Services</h2>

      <div className="container services__container">

        {/* Frontend Development */}
        <article className="service">
          <div className="service__head">
            <h3>Frontend Development</h3>
          </div>
          <ul className='service__list'>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Responsive web applications using ReactJS and NextJS.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>State management with Redux and Context API.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>TypeScript for type-safe development.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>CSS frameworks like Tailwind CSS and Bootstrap for styling.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Performance optimization and accessibility improvements.</p>
            </li>
          </ul>
        </article>

       
        <article className="service">
          <div className="service__head">
            <h3>Backend Development</h3>
          </div>
          <ul className='service__list'>
            <li>
              <BiCheck className='service__list-icon' />
              <p>API Development using Node.js and Express.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Payment API Integrations (e.g., Stripe, PayPal, M-Pesa).</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Java Spring Boot for robust backend services.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Laravel Development for PHP applications.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Microservices Architecture.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Database Management with MongoDB and MySQL.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Real-Time Applications using WebSockets.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>RESTful API development with Laravel.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Spring Data JPA for database interactions in Spring Boot.</p>
            </li>
          </ul>
        </article>

        {/* System Design */}
        <article className="service">
          <div className="service__head">
            <h3>System Design</h3>
          </div>
          <ul className='service__list'>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Designing scalable and efficient system architectures.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Creating detailed design documents and diagrams.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Performance optimization and load balancing.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Integration of various subsystems and components.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Security design and implementation.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Database design and management.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Ensuring system reliability and availability.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Conducting system performance evaluations and improvements.</p>
            </li>
          </ul>
        </article>

        {/* UI/UX Design */}
        <article className="service">
          <div className="service__head">
            <h3>UI/UX Design</h3>
          </div>
          <ul className='service__list'>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Information architecture.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>User Research - Focuses on understanding user behaviors, needs through observations.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>UX Strategy.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Usability Testing - consists of evaluating a product by testing it with your target users.</p>
            </li>
          </ul>
        </article>

        {/* Software Architecture */}
        <article className="service">
          <div className="service__head">
            <h3>Software Architecture</h3>
          </div>
          <ul className='service__list'>
            <li>
              <BiCheck className='service__list-icon' />
              <p>User Interface (UI) Service</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Data Access Service</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Business Logic Service</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Integration Service</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Security Service</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Logging and Monitoring Service</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Configuration Service</p>
            </li>
          </ul>
        </article>

      </div>
    </section>
  );
}

export default Services;
